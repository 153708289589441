import React, { useState, useEffect } from 'react';
// @ts-ignore
import style from './Home.css';
// @ts-ignore
import globalStyle from '../../global-style/global.css'
import { motion, useTransform, useViewportScroll } from 'framer-motion';
import insta from '../../static/images/instagram.png';
import InstaAuth from './instaAuth';
import ScrollingContainer from './scrollingContainer';


function useDarkMode() {
    const [isDarkMode, setIsDarkMode] = useState(
        window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
    );
    useEffect(() => {
        document.title = "fwb.";
    }, []);
    useEffect(() => {
        const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
        const handleChange = (e) => setIsDarkMode(e.matches);

        // Add event listener
        mediaQuery.addEventListener('change', handleChange);

        // Cleanup event listener
        return () => mediaQuery.removeEventListener('change', handleChange);
    }, []);

    return isDarkMode;
}

function Home() {
    const text = "We're just friends. No hard feelings. ";
    const repeatedTextArray = new Array(50).fill(text);

    const isDarkMode = useDarkMode();

    const containers = [
        { duration: 60, direction: "left", classSectionName: "top" },
        { duration: 55, direction: "right", classSectionName: "top2" },
        { duration: 60, direction: "left", classSectionName: "top3" },
        { duration: 65, direction: "right", classSectionName: "top4" },
        { duration: 55, direction: "left", classSectionName: "top5" },
        { duration: 60, direction: "right", classSectionName: "top6" }
    ];    

    return (
        <div style={{ height: '100%', width: '100%', overflow: 'hidden', }}>
            <div className={isDarkMode ? 'background-dark' : 'background-light'}></div>
            {containers.map((container, index) => (
            <ScrollingContainer
                key={index}
                duration={container.duration}
                direction={container.direction}
                classSectionName={container.classSectionName}
                />
            ))}

            <div className="content" >
                <div className="nunito-sans-black" style={{ color: isDarkMode ? '#fff' : '#000', fontSize: 96, margin: 0, marginLeft: 0 }}>fwb</div>
                {/* <div className="nunito-sans-semi-bold" style={{ color: '#5F5F5F', fontSize: 20, margin: 0, position: 'relative', top: -2 }}>We're just friends We're just friends</div> */}
                <div style={{ height: '10px' }}></div>
                <InstaAuth />
        
                {/* <img src={insta} style={{ width: 35, height: 35, marginTop: 30 }} /> */}
            </div>

            <div style={{ position: 'absolute', bottom: 0, overflow: 'hidden', width: '100%' }}>
            {containers.reverse().map((container, index) => (
            <ScrollingContainer
                key={index}
                duration={container.duration}
                direction={container.direction}
                classSectionName={container.classSectionName}
            />
            ))}

            </div>
        </div>
    );

}

export default Home;

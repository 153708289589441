// src/App.js or src/App.jsx
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/home/Home';
import PrivacyAndPolicy from './pages/privacyPolicy/PrivacyPolicy';
import DataDeletion from 'pages/dataDeletion/DataDeletion';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/privacy" element={<PrivacyAndPolicy />} />
        <Route path="/data" element={<DataDeletion />} />
      </Routes>
    </Router>
  );
}

export default App;

import { motion, useTransform, useViewportScroll } from 'framer-motion';
import React, { useEffect, useState } from 'react';

const ScrollingContainer = ({ duration, direction, classSectionName }) => {
    const text = "We're just friends. No hard feelings. ";
    const repeatedTextArray = new Array(50).fill(text);
    
    function useDarkMode() {
        const [isDarkMode, setIsDarkMode] = useState(
            window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
        );
        useEffect(() => {
            document.title = "fwb.";
        }, []);
        useEffect(() => {
            const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
            const handleChange = (e) => setIsDarkMode(e.matches);
    
            // Add event listener
            mediaQuery.addEventListener('change', handleChange);
    
            // Cleanup event listener
            return () => mediaQuery.removeEventListener('change', handleChange);
        }, []);
    
        return isDarkMode;
    }

    const isDarkMode = useDarkMode();

    return (
        <div className="scrolling-container">
                <motion.div
                    className="scrolling-wrapper"
                    initial={{ x: direction === "left" ? 0 : '-90%' }}
                    animate={{ x: direction === "left" ? '-90%' : 0 }}
                    transition={{
                        repeat: Infinity,
                        duration: duration,
                        ease: 'linear'
                    }}
                >
                    <div className="scrolling-text nunito-sans-black">
                        {repeatedTextArray.map((item, index) => (
                            <span 
                                key={index} 
                                className={`scrolling-item ${isDarkMode ? 'scrolling-text-' + classSectionName + '-font-dark' : 'scrolling-text-' + classSectionName + '-font-light'}`} 
                            >
                                {item}
                            </span>
                        ))}
                    </div>
                </motion.div>
            </div>
    );
}

export default ScrollingContainer;
import React, { useState, useEffect } from 'react';



function useDarkMode() {
    const [isDarkMode, setIsDarkMode] = useState(
        window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
    );
    useEffect(() => {
        document.title = "fwb.";
    }, []);
    useEffect(() => {
        const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
        const handleChange = (e) => setIsDarkMode(e.matches);

        // Add event listener
        mediaQuery.addEventListener('change', handleChange);

        // Cleanup event listener
        return () => mediaQuery.removeEventListener('change', handleChange);
    }, []);

    return isDarkMode;
}

function DataDeletion() {
    const text = "We're just friends. No hard feelings. ";
    const repeatedTextArray = new Array(50).fill(text);

    const isDarkMode = useDarkMode();



    return (
        <div style={{ height: '100%', width: '100%', overflow: 'hidden', }}>
            <h1>Privacy Policy</h1>
            <p>To delete your user data and/or Facebook data, please reach out at: contact.fwb.wtf@gmail.com. Your data will be removed within 1-2 business days.</p>
        </div>
    );

}

export default DataDeletion;

import React, { useEffect } from 'react';
import axios from 'axios';
import instaBlack from '../../static/images/instagram_black.png';
import instaWhite from '../../static/images/instagram.png';
import { transform } from 'framer-motion';

const CLIENT_ID = '1208991216683949';
const CLIENT_SECRET = '27053e96108eef5c245ec8fb202e1c12';
const REDIRECT_URI = 'https://fwb.wtf/';
const AUTHORIZATION_URL = `https://api.instagram.com/oauth/authorize?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&scope=user_profile,user_media&response_type=code`;

const InstaAuth = () => {

  const handleLogin = () => {
    window.location.href = AUTHORIZATION_URL;
  };


  // TODO: Check if this works after deployment because you can't test with localhost
  useEffect(() => {
    const fetchAccessToken = async (code) => {
      const qs = require('qs');

      const response = await axios.post('https://api.instagram.com/oauth/access_token', 
        qs.stringify({
          client_id: CLIENT_ID,
          client_secret: CLIENT_SECRET,
          grant_type: 'authorization_code',
          redirect_uri: REDIRECT_URI,
          code: code,
        }),
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": "true",
            "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
            "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers"
          }
        }
      );
            
      console.log(response);
      // Store the access token in local storage or use it in your application logic

      console.log("Access token:");
      console.log(response.data);
      console.log(response.data["access_token"]);
      console.log("----");

      const userDataResponse = await axios.get(`https://graph.instagram.com/me?fields=id,username,account_type,profile_picture_url&access_token=${response.data["access_token"]}`);
      console.log("-----");
      console.log(userDataResponse.data);
      console.log("-----");
      
      const profilePic = await axios.get('https://cors-anywhere.herokuapp.com/https://www.instagram.com/asp.films/?__a=1&__d=1');
      console.log(profilePic);

    };

    const query = new URLSearchParams(window.location.search);
    const code = query.get('code');
    if (code) {
      fetchAccessToken(code);
    }
  }, []);

  const [hover, setHover] = React.useState(false);

  const buttonStyle = { // Instagram's brand color
    color: '#423F3F',
    border: 'none',
    borderRadius: '25px',
    display: 'flex',
    padding: '11px 30px',
    fontSize: '16px',
    cursor: 'pointer',
    justifyContent: 'start',
    alignItems: 'center',
    boxShadow: 'rgba(0, 0, 0, 0.25) 0px 25px 50px -12px',
    backgroundColor: '#fff',
    transition: '0.3s ease',
    
  };

  const buttonHoverStyle = {
    padding: '11px 50px',

    boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px',
  };


  return (
    <div>
      <button
        onClick={handleLogin}
        style={{ ...buttonStyle, ...(hover ? buttonHoverStyle : {}) }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <img src={instaWhite} alt="" style={{ width: '30px' }} />
        <p style={{ margin: 0, position: 'relative', color: '#000', fontSize: '17px', marginLeft: '10px' }}>Join with Instagram</p>
      </button>
    </div>
  );
}

export default InstaAuth;
